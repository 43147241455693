import React, { useState, useEffect } from "react";
import img9 from "../../assets/img/img9.png";
import img10 from "../../assets/img/img10.png";
import img11 from "../../assets/img/img11.png";
import { useInView } from "react-intersection-observer";
import 'animate.css';

const WatchSection1 = ({ title, description, title2, description2 }) => {
  const { ref: statsRef, inView: statsInView } = useInView({
    triggerOnce: false,
    threshold: 0.2,
  });

  const [activeContent, setActiveContent] = useState('iron');
  const [titleAnimationClass, setTitleAnimationClass] = useState('');
  const [descriptionAnimationClass, setDescriptionAnimationClass] = useState('');
  const [imageAnimationClass, setImageAnimationClass] = useState('');
  const [listAnimationClass, setListAnimationClass] = useState('');

  useEffect(() => {
    if (statsInView) {
      setTitleAnimationClass('animate__fadeInUp');
      setDescriptionAnimationClass('animate__fadeInUp');
      setImageAnimationClass('animate__fadeInLeft');
      setListAnimationClass('animate__fadeInLeft');
      
      const timeout = setTimeout(() => {
        setTitleAnimationClass('');
        setDescriptionAnimationClass('');
        setImageAnimationClass('');
        setListAnimationClass('');
      }, 1000);
      
      return () => clearTimeout(timeout);
    }
  }, [statsInView, activeContent]);

  const handleImageChange = (content) => {
    // إخفاء العناصر قبل التغيير
    setTitleAnimationClass('animate__fadeOut');
    setDescriptionAnimationClass('animate__fadeOut');
    setImageAnimationClass('animate__fadeOut');
    
    // إضافة تأخير لمدة ثانية ثم عرض العناصر الجديدة
    setTimeout(() => {
      setActiveContent(content);
      setTitleAnimationClass('animate__fadeInUp');
      setDescriptionAnimationClass('animate__fadeInUp');
      setImageAnimationClass('animate__zoomIn');
    }, 1000);
  };

  const currentTitle = activeContent === 'iron' ? title : title2;
  const currentDescription = activeContent === 'iron' ? description : description2;
  const currentImage = activeContent === 'iron' ? img10 : img11;

  return (
    <div className="myClass2 watch-section1  relative h-screen bg-black text-white" ref={statsRef}>
      <div className="absolute h-full inset-0 bg-cover bg-center opacity-50" style={{ backgroundImage: `url(${img9})` }}></div>

      <div className="flex justify-center   items-center h-full">
        <div className="grid grid-cols-1 -mt-24 md:mt-0  md:grid-cols-3 gap-4 h-[90%] relative container md:mx-auto px-4 md:px-8">
          <div className="w-full h-[300px] md:h-full overflow-hidden md:col-span-2 flex flex-col justify-center items-start px-4 md:px-20" dir="ltr">
            <h1
              className={`
                ${titleAnimationClass} text-xl md:text-4xl lg:text-6xl font-bold mb-4 md:mb-6 animate__animated
              `}
            >
              {currentTitle}
            </h1>
            <p
              className={`
                ${descriptionAnimationClass} text-sm md:text-lg lg:text-xl mb-4 md:mb-6 whitespace-normal w-full md:w-[400px] animate__animated
              `}
            >
              {currentDescription}
            </p>
          </div>

          <div className="flex flex-col h-[200px] -mt-48 md:mt-0  md:h-full overflow-hidden justify-center items-center text-right">
            <div className="relative inline-block p-1 rounded-xl w-full">
              <img
                src={currentImage}
                alt={currentTitle}
                className={`
                  ${imageAnimationClass} rounded-lg animate__animated object-cover w-full h-full
                `}
              />
            </div>
          </div>
        </div>
      </div>

      <div>
        <ul className={`absolute bottom-40 md:bottom-10 right-5 md:right-10 lg:right-20 list-disc list-inside text-sm md:text-base lg:text-lg animate__animated`}>
          <li
            className={`mb-2 cursor-pointer ${activeContent === 'iron' ? 'text-white' : 'text-gray-500'} hover:animate__animated hover:animate__pulse`}
            onClick={() => handleImageChange('iron')}
          >
            Iron Product
          </li>
          <li
            className={`mb-2 cursor-pointer ${activeContent === 'glass' ? 'text-white' : 'text-gray-500'} hover:animate__animated hover:animate__pulse`}
            onClick={() => handleImageChange('glass')}
          >
            Glass Product
          </li>
        </ul>
      </div>
    </div>
  );
};

export default WatchSection1;
