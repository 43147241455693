import React from "react";
import CountUp from "react-countup";
import { useInView } from 'react-intersection-observer';

const StatisticCard = ({ colSpan2, imgSrc, largeText, smallText, colSpan }) => {
  const { ref, inView } = useInView({
    triggerOnce: false, // الأنميشن ستعمل في كل مرة يتم فيها التمرير إلى العنصر
    threshold: 0.1, // تشغيل الأنميشن عندما يظهر 10% من العنصر في الشاشة
  });

  return (
    <div
      ref={ref} // ربط العنصر بـ useInView
      className={`
      md:${colSpan} ${colSpan2}  
      relative bg-[#1E1E1E] md:h-[240px] h-[140px] overflow-hidden rounded-3xl flex flex-col justify-center items-center
      ${inView ? 'animate__animated animate__fadeIn animate__delay-1s' : 'opacity-0'}
    `}
    >
      {imgSrc && (
        <img 
          src={imgSrc} 
          className="w-full h-full object-cover opacity-50 transition-opacity duration-500 hover:opacity-75" 
        />
      )}
      <p
        className={` ${
          imgSrc ? "absolute top-5" : ""
        } text-white md:text-[100px] text-[50px] ${inView ? 'animate__animated animate__fadeInDown' : 'opacity-0'}`}
      >
        {inView && (
          <CountUp start={0} end={parseInt(largeText, 10)} duration={2.5} />
        )}
      </p>
      <p
        className={`
      ${imgSrc ? "absolute bottom-6" : ""}
          text-white md:text-[30px] text-[15px] ${inView ? 'animate__animated animate__fadeInUp' : 'opacity-0'}
        `}
      >
        {smallText}
      </p>
    </div>
  );
};

export default StatisticCard;
