import { BASIC_URL } from "../index";

export const fetchDataProducts = async ({ search, paginate, page  , categoryId}) => {
    try {
       
        const myHeaders = new Headers({
            "Content-Type": "application/json",
        });

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
            credentials: 'include',
        };

        const response = await fetch(`${BASIC_URL}/productsbycategory/${categoryId}?search=${search}&page=${page}&per_page=${paginate}`, requestOptions);

        if (!response.ok) {
            if (response.statusText === "Unauthorized") {
                localStorage.removeItem("token");
                window.location.reload();
            }
            const errorMessage = `An error occurred: ${response.statusText}`;
            throw new Error(errorMessage);
        }

        const data = await response.json();
        if (!data.success) {
            return {
                data: [],
                loading: false,
                hasErrors: true,
                total: 0,
                message: data.message || "Error fetching products.",
            };
        }
        const list = data.products.data.map((item, index) => ({
            key: index,
            index: index + 1,
            id: item.id,
            name_ar: item.name_ar,
            name_en: item.name_en,
            description_ar: item.description_ar,
            description_en: item.description_en,
            length: item.length,
            height: item.height,
            depth: item.depth,
            category_id: item.category_id,
            images: item.images,

        }));

        return {
            data: list,
            loading: false,
            hasErrors: false,
            total: data.products.total,
        };
    } catch (error) {
        console.error("Error fetching products:", error.message);

        if (error.message.includes("Unauthorized")) {
            localStorage.removeItem("token");
            window.location.reload();
        }

        return {
            data: [],
            loading: false,
            hasErrors: true,
            total: 0,
            message: error.message,
        };
    }
};

export const fetchDataCategories = async ({ search, paginate , page }) => {
    try {
       
        const myHeaders = new Headers({
            "Content-Type": "application/json",
        });

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
            credentials: 'include',
        };

        const response = await fetch(`${BASIC_URL}/CategoryHasProducts?search=${search}&page=${page}&per_page=${paginate}`, requestOptions);

       

        const data = await response.json();
        if (!data.success) {
            return {
                data: [],
                loading: false,
                hasErrors: true,
                total: 0,
                message: data.message || "Error fetching categories.",
            };
        }

        const list = data.categories.data.map((item, index) => ({
            key: index,
            index: index + 1,
            id: item.id,
            name_ar: item.name_ar,
            name_en: item.name_en,
           
        }));

        return {
            data: list,
            loading: false,
            hasErrors: false,
            total: data.categories.total,
        };
    } catch (error) {
        console.error("Error fetching categories:", error.message);
        
        // Additional check in case error was caught due to other reasons
        if (error.message.includes("Unauthorized")) {
            localStorage.removeItem("token");
            window.location.reload();
        }

        return {
            data: [],
            loading: false,
            hasErrors: true,
            total: 0,
            message: error.message,
        };
    }
};


export const fetchDataProductsById = async ({id}) => {
    try {
       
        const myHeaders = new Headers({
            "Content-Type": "application/json",
        });

        const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
            credentials: 'include',
        };

        const response = await fetch(`${BASIC_URL}/product/${id}`, requestOptions);
        const data = await response.json();
        if (!data.success) {
            return {
                data: [],
                loading: false,
                hasErrors: true,
                total: 0,
                message: data.message || "Error fetching products.",
            };
        }
        const list = {
            key: data.product.id,
            index:  data.product ,
            id:  data.product.id,
            name_ar:  data.product.name_ar,
            name_en:  data.product.name_en,
            description_ar:  data.product.description_ar,
            description_en:  data.product.description_en,
            length:  data.product.length,
            height:  data.product.height,
            depth:  data.product.depth,
            category_id:  data.product.category_id,
            images:  data.product.images,

        };

        return {
            data: list,
            loading: false,
            hasErrors: false,
        };
    } catch (error) {
        console.error("Error fetching products:", error.message);

        if (error.message.includes("Unauthorized")) {
            localStorage.removeItem("token");
            window.location.reload();
        }

        return {
            data: [],
            loading: false,
            hasErrors: true,
            total: 0,
            message: error.message,
        };
    }
};