import React, { useEffect, useState } from "react";
import StatisticCard from "../StatisticCard/StatisticCard";
import img2 from "../../assets/img/img2.png";
import img3 from "../../assets/img/img3.png";
import { useInView } from 'react-intersection-observer';
import { fetchDataSettings } from '../../api/settingsApi/index.js';

const HeroSection = ({ imgSrc, title, subtitle }) => {
  const [dataSource, setDataSource] = useState([]);

  const fetchDataS = async () => {
    try {
      const data = await fetchDataSettings();
      setDataSource(data);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchDataS();
  }, []);

  const { ref: heroRef, inView: heroInView } = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });

  return (
    <div id="home" className="h-screen  flex flex-col justify-center items-center bg-gray-100">
      {/* Hero Section */}
      <div 
        ref={heroRef}
        className={`relative w-full h-[32vh] -mt-24 md:mt-0 md:h-[60vh] flex items-center justify-center bg-black ${
          heroInView ? 'animate__animated animate__fadeIn animate__slower' : 'opacity-0'
        }`}
      >
        <img 
          className="absolute inset-0 w-full h-full object-cover opacity-70" 
          src={imgSrc} 
          alt="Hero" 
        />
        <div 
          className="absolute inset-0 bg-gradient-to-r from-black via-transparent to-black opacity-80"
        ></div>
        <div className="z-10 text-center text-white px-4 md:px-10">
          <h1 className={`text-4xl md:text-6xl font-bold mb-4 ${
            heroInView ? 'animate__animated animate__fadeIn  animate__slow' : 'opacity-0'
          }`}>
            {title}
          </h1>
          <p className={`text-lg md:text-2xl ${
            heroInView ? 'animate__animated animate__fadeInUp animate__slow' : 'opacity-0'
          }`}>
            {subtitle}
          </p>
        </div>
      </div>

      {/* Statistic Cards */}
      <div
        className={`mt-8 mb-8 md:mb-1 px-4 w-full grid grid-cols-2 md:grid-cols-4 gap-6 ${
          heroInView ? 'animate__fadeInUp animate__delay-1s' : 'opacity-0'
        }`}
      >
        <StatisticCard 
          colSpan="col-span-2" 
          colSpan2="col-span-2" 
          largeText={dataSource?.data?.[0]?.number_of_workers || "0"} 
          smallText="يد عامله على المشاريع" 
          className={`${heroInView ? 'animate__zoomIn animate__delay-2s' : 'opacity-0'}`}
        />
        <StatisticCard 
          colSpan="col-span-1" 
          imgSrc={img3} 
          largeText={dataSource?.data?.[0]?.number_of_completed_projects || "0"} 
          smallText="مشروع منفذ" 
          className={`${heroInView ? 'animate__zoomIn animate__delay-3s' : 'opacity-0'}`}
        />
        <StatisticCard 
          colSpan="col-span-1" 
          imgSrc={img2} 
          largeText={dataSource?.data?.[0]?.company_age || "0"} 
          smallText="عمر الشركة" 
          className={`${heroInView ? 'animate__zoomIn animate__delay-4s' : 'opacity-0'}`}
        />
      </div>
    </div>
  );
};

export default HeroSection;
