import React from "react";
import img12 from "../../assets/img/img12.png";
import 'animate.css';
import { useInView } from 'react-intersection-observer';
import logo from "../../assets/img/logo.png";

const ContactAs = () => {
  const { ref: formRef, inView: formInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const { ref: footerRef, inView: footerInView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <div className="flex myClass2 flex-col min-h-screen bg-[#202225] text-white">
      {/* قسم النموذج والصورة */}
      <div className="container mx-auto flex flex-col lg:flex-row items-center rounded-xl p-6 md:mt-20 pt-12 md:pt-24 min-h-[70vh] md:min-h-[auto]">
        {/* قسم النموذج */}
        <div
          ref={formRef}
          className={`w-full lg:w-1/2 mb-8 lg:mb-0 lg:pr-12 ${
            formInView ? 'animate__animated animate__fadeInLeft' : ''
          }`}
        >
          <h2 className={`text-2xl sm:text-3xl lg:text-4xl font-bold mb-6 ${
              formInView ? 'animate__animated animate__fadeInUp' : ''
            }`}>
            تواصل معنا
          </h2>

          <form className={`space-y-4 ${
            formInView ? 'animate__animated animate__fadeInUp animate__delay-1s' : ''
          }`}>
            <input
              type="text"
              placeholder="ادخل الاسم"
              className="w-full p-3 bg-[#2c2f33] border border-gray-600 rounded-lg text-white focus:outline-none"
            />
            <input
              type="email"
              placeholder="ادخل البريد الالكتروني"
              className="w-full p-3 bg-[#2c2f33] border border-gray-600 rounded-lg text-white focus:outline-none"
            />
            <textarea
              placeholder="الرسالة"
              className="w-full p-3 bg-[#2c2f33] border border-gray-600 rounded-lg text-white focus:outline-none h-32 resize-none"
            ></textarea>
            <button
              type="submit"
              className="w-full bg-yellow-500 text-black p-3 rounded-lg font-bold hover:bg-yellow-600 transition"
            >
              ارسال
            </button>
          </form>

          {/* روابط التواصل الاجتماعي */}
          <div className={`mt-8 ${
            formInView ? 'animate__animated animate__fadeInUp animate__delay-2s' : ''
          }`}>
            <h3 className="text-lg mb-4">تواصل معنا عبر:</h3>
            <div className="flex flex-wrap gap-2">
              <button className="bg-[#2c2f33] border border-gray-600 text-white px-4 py-2 rounded-lg hover:bg-gray-700 transition">
                WhatsApp
              </button>
              <button className="bg-[#2c2f33] border border-gray-600 text-white px-4 py-2 rounded-lg hover:bg-gray-700 transition">
                Telegram
              </button>
              <button className="bg-[#2c2f33] border border-gray-600 text-white px-4 py-2 rounded-lg hover:bg-gray-700 transition">
                Facebook
              </button>
            </div>
          </div>

          {/* رقم الهاتف */}
          <div className={`mt-8 ${
            formInView ? 'animate__animated animate__fadeInUp animate__delay-3s' : ''
          }`}>
            <h3 className="text-lg mb-2">رقم الهاتف:</h3>
            <p className="bg-[#2c2f33] border border-gray-600 text-white px-4 py-2 rounded-lg">
              07708150141
            </p>
          </div>
        </div>

        {/* قسم الصورة */}
        <div className={`w-full lg:w-1/2 flex justify-center ${
          formInView ? 'animate__animated animate__fadeInRight' : ''
        }`}>
          <img
            className="w-full max-w-lg h-auto rounded-lg shadow-lg hidden md:block"
            src={img12}
            alt="Contact Image"
          />
        </div>
      </div>

      {/* قسم الفوتر */}
      <footer ref={footerRef} className="container mx-auto flex flex-col lg:flex-row justify-between items-center px-6 py-6 bg-[#202225] min-h-[70vh] md:min-h-[auto]">
          {/* قسم الشعار */}
          <div className={`flex items-center mb-6 lg:mb-0 ${footerInView ? 'animate__animated animate__fadeInLeft' : ''}`}>
            <img
              src={logo}
              alt="Logo"
              className="w-16 lg:w-20 mr-4"
            />
            <div className="border-r border-gray-600 pr-4">
              <p className="font-bold text-2xl">exa iQ</p>
            </div>
          </div>

          {/* روابط التنقل */}
          <nav className={`flex hidden md:block flex-row space-y-4 lg:space-y-0 space-x-reverse space-x-6 ${footerInView ? 'animate__animated animate__fadeInRight' : ''}`}>
            <a href="#" className="hover:underline">
              الرئيسية
            </a>
            <a href="#" className="hover:underline">
              من نحن
            </a>
            <a href="#" className="hover:underline">
              المشاريع
            </a>
            <a href="#" className="hover:underline">
              المنتجات
            </a>
          </nav>
        </footer>
    </div>
  );
};

export default ContactAs;
