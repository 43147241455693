import React, { useEffect, useState } from "react";
import img6 from "../../assets/img/img6.png";
import img7 from "../../assets/img/img7.png";
import icon1 from "../../assets/icons/icon1.png";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import { fetchDataSettings } from "../../api/settingsApi";

const AboutUs = () => {
  const { ref: statsRef, inView: statsInView } = useInView({
    triggerOnce: false, // السماح بتكرار الأنميشن عند كل مرة يظهر العنصر
    threshold: 0.2,
  });
  const { ref: imageRef, inView: imagesInView } = useInView({
    triggerOnce: false, // اجعل الأنميشن يتكرر عند كل زيارة
    threshold: 0.2,
  });

  const [dataSource, setDataSource] = useState([]);

  const fetchDataS = async () => {
    try {
      const data = await fetchDataSettings();
      setDataSource(data);
    } catch (error) {
      console.log("Validate Failed:", error);
    }
  };

  useEffect(() => {
    fetchDataS();
  }, []);

  return (
    <div
      dir="rtl"
      ref={statsRef}
      className="h-[100vh] myClass2 overflow-hidden md:pt-20 grid grid-cols-1 md:grid-cols-3 gap-3 mt-5 mb-0 mx-2 md:mx-10"
    >
      <div className="col-span-1 md:mt-20 md:col-span-2 flex flex-col items-start md:items-end md:ml-24">
        <motion.div
          initial={{ opacity: 0, x: -100 }}
          animate={statsInView ? { opacity: 1, x: 0 } : {}}
          transition={{ duration: 1 }}
          className="w-full flex justify-start md:justify-end"
        >
          <p className="text-5xl md:text-[100px] font-bold">About Us</p>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, width: 0 }}
          animate={statsInView ? { opacity: 1, width: "30%" } : {}}
          transition={{ duration: 1, delay: 0.5 }}
          className="w-[150px] md:w-[100px] h-[1px] bg-black mt-4 mb-5 md:mb-8"
        />

        <motion.p
          initial={{ opacity: 0, y: 50 }}
          animate={statsInView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 1, delay: 1 }}
          className="aboutAs whitespace-normal md:text-lg text-sm leading-relaxed md:w-[500px] w-full mb-10 md:mb-0 h-[150px] md:h-[300px] px-6 py-4 overflow-y-scroll mt-0 md:mt-20 text-right"
        >
          {dataSource && dataSource.data && dataSource.data[0].about_us_ar}
        </motion.p>
      </div>

      <div ref={imageRef} className="col-span-1 md:col-span-1 md:mt-20 h-full">
        <div className="flex flex-col h-full  md:mt-0 gap-4">
          <motion.div
            initial={{ height: "400px" }} // الحجم الابتدائي في الشاشات الكبيرة
            animate={imagesInView ? { height: "250px" } : {}} // يتغير الحجم إلى 250px
            transition={{ duration: 1, delay: 1.2 }} // مدة التأثير
            className="overflow-hidden relative"
          >
            <img
              className="w-full md:h-full h-[200px] object-cover rounded-xl"
              src={img7}
              alt="image1"
            />
            <img
              src={icon1}
              alt="image1"
              className=" absolute  top-5 right-5 w-10"
            />
          </motion.div>

          <motion.div
            initial={{ height: "300px" }} // الحجم الابتدائي
            animate={imagesInView ? { height: "500PX" } : {}} // يتغير الحجم إلى ملء المساحة
            transition={{ duration: 1, delay: 1.4 }} // مدة التأثير
            className="overflow-hidden relative"
          >
            <img
              className="w-full h-[200px] md:h-full object-cover rounded-xl"
              src={img6}
              alt="image2"
            />
            <img
              src={icon1}
              alt="image1"
              className=" absolute  top-5 right-5 w-10"
            />
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
