import { BASIC_URL } from "../index";

export const fetchDataSettings = async () => {
    try {
        const myHeaders = new Headers({
            "Content-Type": "application/json",
        });

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
            credentials: 'include',
        };

        const response = await fetch(`${BASIC_URL}/settings`, requestOptions);

        if (!response.ok) {
            const errorMessage = `An error occurred: ${response.statusText}`;
            throw new Error(errorMessage);
        }

        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
            const data = await response.json();

            if (!data.success) {
                return {
                    data: [],
                    loading: false,
                    hasErrors: true,
                    total: 0,
                    message: data.message,
                };
            }

            const list = data.settings.map((item, index) => ({
                key: index,
                index: index + 1,
                id: item.id,
                name: item.name,
                number_of_workers: item.number_of_workers,
                company_age: item.company_age,
                number_of_completed_projects: item.number_of_completed_projects,
                phone_number: item.phone_number,
                whatsapp: item.whatsapp,
                facebook: item.facebook,
                telegram: item.telegram,
                about_us_ar: item.about_us_ar,
                about_us_en: item.about_us_en,
                created_at: item.created_at,
                updated_at: item.updated_at,
            }));

            return {
                data: list,
                loading: false,
                hasErrors: false,
                total: data.settings.length,
            };
        } else {
            throw new Error("Response is not JSON");
        }
    } catch (error) {
        console.error("Error fetching data:", error.message);

        return {
            data: [],
            loading: false,
            hasErrors: true,
            total: 0,
            message: error.message,
        };
    }
};
