import React from "react";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";

const ProjectDetailSection = ({ dataSource }) => {
  const { ref: statsRef, inView: statsInView } = useInView({
    triggerOnce: false,
    threshold: 0.2,
  });

  const isDataAvailable =
    dataSource && dataSource.data && dataSource.data.length > 0;

  const imageAnimation = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  };

  const textAnimation = {
    hidden: { opacity: 0, x: 50 },
    visible: { opacity: 1, x: 0, transition: { duration: 1, delay: 0.5 } },
  };

  return (
    <div
      ref={statsRef}
      className="min-h-screen myClass2 overflow-hidden flex flex-col md:flex-row items-center justify-center p-4 gap-10"
    >
      {isDataAvailable && (
        <motion.div
          className="relative w-full -mt-24 md:mt-0 max-w-xl md:max-w-4xl h-[32vh] md:h-[70vh] shadow-lg" // إضافة الظل
          initial={{ opacity: 0, y: -100 }} // يبدأ العنصر من الأعلى
          animate={statsInView ? { opacity: 1, y: 0 } : {}} // يتحرك إلى موضعه الطبيعي
          transition={{ duration: 1 }} // مدة الحركة
        >
          {/* الصورة */}
          <img
            className="w-full h-full object-cover rounded-xl"
            src={dataSource.data[0].images[0].image_url}
            alt="Project Image 1"
          />

          {/* إضافة التدرج الذي يظهر كظل أسفل الصورة */}
          <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent rounded-xl"></div>

          {/* النص الذي يظهر فوق الصورة */}
          <motion.p
            className="absolute right-10 md:bottom-[100px] bottom-[70px] font-bold text-white md:text-[50px] text-[30px]"
            initial={{ opacity: 0, y: -50 }} // يبدأ النص من الأعلى
            animate={statsInView ? { opacity: 1, y: 0 } : {}} // يتحرك إلى موضعه الطبيعي
            transition={{ duration: 1, delay: 0.3 }} // تأخير بسيط لحركة النص
          >
            {dataSource.data[0].name_ar}
          </motion.p>

          <motion.p
            className="absolute right-10 md:bottom-[70px] bottom-[40px] text-white md:text-xl text-sm whitespace-normal md:w-[400px] w-[200px] text-right"
            initial={{ opacity: 0, y: -50 }} // يبدأ النص من الأعلى
            animate={statsInView ? { opacity: 1, y: 0 } : {}} // يتحرك إلى موضعه الطبيعي
            transition={{ duration: 1, delay: 0.5 }} // تأخير أكبر قليلاً للنص الثاني
          >
            {dataSource.data[0].description_ar}
          </motion.p>

          {/* باقي العناصر */}
          <motion.div
            className="absolute top-[20px] left-[10px] flex items-center justify-center w-[120px] h-[60px] md:w-[250px] md:h-[120px] bg-black/30 backdrop-blur-md rounded-3xl border border-white p-1 md:p-4"
            initial={{ opacity: 0, y: -50 }} // يبدأ العنصر من الأعلى
            animate={statsInView ? { opacity: 1, y: 0 } : {}} // يتحرك إلى موضعه الطبيعي
            transition={{ duration: 1, delay: 0.7 }} // تأخير أكبر لحركة هذا العنصر
          >
            <div className="text-center">
              <p className="md:text-3xl text-sm font-bold text-white">
                <span>م</span> <span>{dataSource.data[0].area}</span>
              </p>
              <p className="md:text-lg text-[10px] md:mt-5 mt-1 text-white">
                مساحة المشروع
              </p>
            </div>
          </motion.div>

          <motion.div
            className="absolute md:top-[150px] top-[100px] left-[10px] flex items-center justify-center w-[120px] h-[60px] md:w-[250px] md:h-[120px] bg-black/30 backdrop-blur-md rounded-3xl border-t-white/50 border-r-white/50 border p-4"
            initial={{ opacity: 0, y: -50 }} // يبدأ العنصر من الأعلى
            animate={statsInView ? { opacity: 1, y: 0 } : {}} // يتحرك إلى موضعه الطبيعي
            transition={{ duration: 1, delay: 0.9 }} // تأخير أكبر لحركة هذا العنصر
          >
            <div className="text-center">
              <p
                className="md:text-3xl text-[10px] font-bold text-white"
                dir="ltr"
              >
                <span>يوم</span>{" "}
                <span>{dataSource.data[0].completion_duration}</span>
              </p>
              <p className="md:text-lg text-sm md:mt-5 mt-1 text-white">
                مدة الانجاز
              </p>
            </div>
          </motion.div>
        </motion.div>
      )}

      {isDataAvailable && dataSource.data.length > 1 && (
      <motion.div
      className="relative w-full max-w-xl md:max-w-4xl h-[32vh] md:h-[70vh]"
      initial={{ opacity: 0, y: 100 }} // يبدأ العنصر من الأسفل
      animate={statsInView ? { opacity: 1, y: 0 } : {}} // يتحرك إلى موضعه الطبيعي
      transition={{ duration: 1 }} // مدة الحركة
    >
      {/* الصورة */}
      <img
        className="w-full h-full object-cover rounded-xl"
        src={dataSource.data[1].images[0].image_url}
        alt="Project Image 2"
      />
    
      {/* التدرج اللوني لإضافة ظل أسفل الصورة */}
      <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent rounded-xl"></div>
    
      {/* النص المعروض فوق الصورة */}
      <motion.p
        className="absolute right-10 md:bottom-[100px] bottom-[70px] font-bold text-white md:text-[50px] text-[30px]"
        initial={{ opacity: 0, y: -50 }} // يبدأ النص من الأعلى
        animate={statsInView ? { opacity: 1, y: 0 } : {}} // يتحرك إلى موضعه الطبيعي
        transition={{ duration: 1, delay: 0.3 }} // تأخير بسيط لحركة النص
      >
        {dataSource.data[0].name_ar}
      </motion.p>
      
      <motion.p
        className="absolute right-10 md:bottom-[70px] bottom-[40px] text-white md:text-xl text-sm whitespace-normal md:w-[400px] w-[200px] text-right"
        initial={{ opacity: 0, y: -50 }} // يبدأ النص من الأعلى
        animate={statsInView ? { opacity: 1, y: 0 } : {}} // يتحرك إلى موضعه الطبيعي
        transition={{ duration: 1, delay: 0.5 }} // تأخير أكبر قليلاً للنص الثاني
      >
        {dataSource.data[0].description_ar}
      </motion.p>
    
      {/* باقي المحتوى */}
      <motion.div
        className="absolute top-[20px] left-[10px] flex items-center justify-center w-[120px] h-[60px] md:w-[250px] md:h-[120px] bg-black/30 backdrop-blur-md rounded-3xl border border-white p-1 md:p-4"
        initial={{ opacity: 0, y: 50 }} // يبدأ العنصر من الأسفل
        animate={statsInView ? { opacity: 1, y: 0 } : {}} // يتحرك إلى موضعه الطبيعي
        transition={{ duration: 1, delay: 0.7 }} // تأخير أكبر لحركة هذا العنصر
      >
        <div className="text-center">
          <p className="md:text-3xl text-sm font-bold text-white">
            <span>م</span> <span>{dataSource.data[1].area}</span>
          </p>
          <p className="md:text-lg text-[10px] md:mt-5 mt-1 text-white">مساحة المشروع</p>
        </div>
      </motion.div>
    
      <motion.div
        className="absolute md:top-[150px] top-[100px] left-[10px] flex items-center justify-center w-[120px] h-[60px] md:w-[250px] md:h-[120px] bg-black/30 backdrop-blur-md rounded-3xl border-t-white/50 border-r-white/50 border p-4"
        initial={{ opacity: 0, y: 50 }} // يبدأ العنصر من الأسفل
        animate={statsInView ? { opacity: 1, y: 0 } : {}} // يتحرك إلى موضعه الطبيعي
        transition={{ duration: 1, delay: 0.9 }} // تأخير أكبر لحركة هذا العنصر
      >
        <div className="text-center">
          <p className="md:text-3xl text-[10px] font-bold text-white" dir="ltr">
            <span>يوم</span> <span>{dataSource.data[1].completion_duration}</span>
          </p>
          <p className="md:text-lg text-sm md:mt-5 mt-1 text-white">مدة الانجاز</p>
        </div>
      </motion.div>
    </motion.div>
    
      )}
    </div>
  );
};

export default ProjectDetailSection;
